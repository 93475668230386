<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">后台首页</a></li>
            <li class="breadcrumb-item active" id="title3">欢迎页面</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">欢迎页面</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3 col-sm-6">
        <div class="card widget-flat text-bg-pink">
          <div class="card-body">
            <div class="float-end">
              <i class="ri-eye-line widget-icon"></i>
            </div>

            <h2 class="my-2">
              {{ formData.by1 }}
            </h2>
            <p class="mb-0">

              <span class="text-nowrap">用户数量</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-xxl-3 col-sm-6">
        <div class="card widget-flat text-bg-info">
          <div class="card-body">
            <div class="float-end">
              <i class="ri-shopping-basket-line widget-icon"></i>
            </div>

            <h2 class="my-2">
              {{ formData.by2 }}
            </h2>
            <p class="mb-0">

              <span class="text-nowrap">商品数量</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xxl-3 col-sm-6">
        <div class="card widget-flat text-bg-primary">
          <div class="card-body">
            <div class="float-end">
              <i class="ri-group-2-line widget-icon"></i>
            </div>

            <h2 class="my-2">
              {{ formData.by3 }}
            </h2>
            <p class="mb-0">

              <span class="text-nowrap">回收订单数量</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xxl-3 col-sm-6">
        <div class="card widget-flat text-bg-purple">
          <div class="card-body">
            <div class="float-end">
              <i class="ri-wallet-2-line widget-icon"></i>
            </div>

            <h2 class="my-2">￥
              {{ formData.by4 }}
            </h2>
            <p class="mb-0">

              <span class="text-nowrap">销售额</span>
            </p>
          </div>
        </div>
      </div>

      <div style="width: 100%;line-height: 30px;text-align: center; padding: 100px;">

        账号：<b style="color: red;">{{ userLname }}</b>，
        身份：<b style="color: red;">{{ role }}</b><br>

        您好，欢迎使用二手旧衣物回收平台！<br>

        请在左侧菜单中选择您要进行的操作！




      </div>

    </div>
  </div>
</template>

<script>

import request, { base } from "../../../utils/http";
export default {

  data() {
    return {
      userLname: "",
      role: "",
      formData: {}, //表单数据
    };
  },
  mounted() {
    this.userLname = sessionStorage.getItem("userLname");
    this.role = sessionStorage.getItem("role");
  },

  created() {
    this.getDatas();
  },

  methods: {



    //获取列表数据
    getDatas() {
      var user = JSON.parse(sessionStorage.getItem("user"));
      let para = {
      };
      this.listLoading = true;
      let url = base + "/admin/get?id=" + user.adminid;
      request.post(url, para).then((res) => {
        this.formData = JSON.parse(JSON.stringify(res.resdata));
        this.listLoading = false;


      });
    },
  },
};

</script>


<style scoped></style>


